<template>
  <div class="calendar-month template-1">
    <div
      class="to-be-scheduled"
      v-if="toBeScheduled.length > 0 && allowPage && iCanSee(view_calendar_event_controls.calendar_add_event_button)"
    >
      <div class="container mx-lg">
        <header>
          <h3>
            {{ translations.tcToBeScheduled }}
            <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcToBeScheduled">
              <i-tooltip />
            </span>
          </h3>
          <div @click.prevent="toBeScheduled = !toBeScheduled">
            <iClose />
          </div>
        </header>
        <div class="scheduled-buttons d-flex flex-wrap">
          <b-button
            variant="tertiary"
            class="btn-w-sm"
            v-for="(item, index) in toBeScheduled"
            :key="index"
            @click="addToBeScheduleEvent(item)"
          >
            <span>{{ item.vmtt_trn_txt }}</span>
            <iAdd />
          </b-button>
        </div>
      </div>
    </div>
    <page-header class="mx-lg">
      <h1>{{ translations.tcCalendar }}</h1>
    </page-header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="i18nSecurityBanner"></security-banner>
    <div v-if="allowPage">
      <camp-selector
        @camp_changed="page_load()"
        :tooltip="translations.tcCurrentHome"
        :i18n="translations.components"
      ></camp-selector>
      <!-- / Camp Selector -->
      <page-body class="mx-lg">
        <header>
          <div class="cal-key-cont">
            <CalendarKey
              v-for="(eve, index) in EventKey"
              :key="index"
              :obj="eve"
              :index="index"
              :i18n="translations.components"
            />
          </div>
        </header>
        <section class="section-1 bg-block">
          <CalendarToolbar
            :switch_toggle="switchM"
            :i18n="translations.components"
            @toggleView="switchView"
            @filterby="filterMeeting"
            @today="today($event)"
          />
          <section :class="switchM == 'grid' ? 'fullcalvis' : 'fullcalinv'">
            <FullCalendar ref="fullcalendar" :options="calendarOptions"></FullCalendar>
          </section>
          <div class="month-view" v-if="switchM == 'month'">
            <div class="day-view" v-for="(day, date) in days" :key="date">
              <h3>{{ returnFormattedDate(date) }}</h3>
              <template v-for="(ev, index) in day">
                <CalendarWeek :obj="ev" :key="index" @onSelected="getSelected($event)" />
              </template>
            </div>
            <div class="day-view" v-if="days && Object.keys(days).length === 0 && days.constructor === Object">
              <p>{{ translations.tcNoEventsScheduled }}</p>
            </div>
          </div>
        </section>
      </page-body>
    </div>
  </div>
  <!-- / Page Body -->
</template>

<script>
/* eslint-disable */
import { mapActions, mapState, mapGetters } from 'vuex'
import { Calendar } from 'vue-bootstrap4-calendar'
import CalendarKey from '@/components/calendar/calendar-key/CalendarKey.vue'
import { calendarMixin } from '@/mixins/calendarMixin'
import calendarService from '../../services/calendarService'
import CalendarToolbar from '@/components/calendar/calendar-toolbar/CalendarToolbar.vue'
import CalendarWeek from '@/components/calendar/calendar-week/CalendarWeek.vue'
import CampSelect from '@/components/CampSelect.vue'
import constantData from '@/json/data.json'
import moment from 'moment'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import allLocales from '@fullcalendar/core/locales-all'

import iAdd from '@/assets/svgs/icon-add-button.vue'
import iClose from '@/assets/svgs/icon-close.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import { Loader } from '@/mixins/Loader'
import pageBody from '@/components/page-components/PageBody.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'
import ExtractEventTitles from '@/services/utils/ExtractEventTitles.js'

export default {
  name: 'calendar-month',
  mixins: [calendarMixin, Loader, translationMixin],
  data() {
    return {
      view_calendar_event_controls: {
        calendar_add_event_button: '20a78086-7644-4a38-9162-085f27489166'
      },
      translations: {},
      i18nSecurityBanner: { 'security-banner': {} },
      toBeScheduled: [],
      dataModal: null,
      isOpen: false,
      switchM: 'month',
      events: [],
      tempEvents: [],
      EventKey: [
        {
          color: '#1ca3c2',
          type: 'Gideon'
        },
        {
          color: '#E43300',
          type: 'Auxiliary'
        },
        {
          color: '#290048',
          type: 'Both'
        }
      ],
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'dayGridMonth',
        handleWindowResize: true,
        allDaySlot: false,
        eventDisplay: 'block',
        selectable: true,
        weekends: true,
        eventClick: this.selectedEvent,
        contentHeight: 580,
        locales: allLocales,
        locale: 'en'
      }
    }
  },
  methods: {
    ...mapActions({
      clearSelected: 'eventCalendar/clearSelected',
      retrieveVideoVisitDetails: 'card/retrieveVideoVisitDetails',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedConventionKey: 'user/setSelectedConventionKey',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey',
      setSelectedMeeting: 'eventCalendar/setSelectedMeeting',
      setSelectedMeetingKey: 'user/setSelectedMeetingKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey'
    }),
    async page_load() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.getViewTranslations('forms'),
        this.getComponentTranslations('calendar-key', 'calendar-toolbar', 'security-banner', 'camp-select'),
        this.getComponentTranslations('common.calendar-event-type', 'common.calendar-event-type-state'),
        ExtractEventTitles(this.prefCulture)
      ]).then(results => {
        this.calendarOptions.locale = this.userLogin.pref_culture
        this.stripReadableText(results[3])

        const newCalendarEventTypesTranslations = results[4] // Get the returned translations

        if (newCalendarEventTypesTranslations) {
          this.translations.newCalendarEventTypes = newCalendarEventTypesTranslations // Set the translations
        }

        this.translations.common['event-types'] = {
          // merge event types together
          ...this.translations.common['calendar-event-type'],
          ...this.translations.common['calendar-event-type-state']
        }
        const translatedText = {
          ...results[2],
          common: {
            ...this.translations.common
          }
        }
        this.$set(this.translations, 'components', translatedText)

        this.updateEventKeys()
        // limit translations to those required by 'security-banner' component.
        this.i18nSecurityBanner['security-banner'] = results[3]['security-banner']

        this.getStateAndCampMeetings(null, null)
        this.setLoadingStatus(false)
      })
    },
    updateEventKeys() {
      const { tcAuxiliary, tcBoth, tcGideon } = this.translations.components['calendar-toolbar']
      this.EventKey[0].type = tcGideon
      this.EventKey[1].type = tcAuxiliary
      this.EventKey[2].type = tcBoth
      return true
    },
    switchMonth() {
      this.switchMonth = 'switch'
    },
    switchView(val) {
      this.switchM = val
    },
    printTime(date, time) {
      let hours = date.getHours()
      if (hours !== 0) {
        let newDate = new Date(date.getTime() + date.getTimezoneOffset())
        let offset = date.getTimezoneOffset() / 60
        newDate.setHours(hours - offset)
        return newDate.getTime()
      } else {
        let pAdjustment = 0
        if (time.indexOf('AM') > -1) {
          if (Number(time.split(':')[0]) == 12) {
            pAdjustment = -12
          }
        } else {
          if (Number(time.split(':')[0]) > 12) {
            pAdjustment = 12
          }
        }
        let phours = Number(time.split(':')[0]) + pAdjustment
        let newDate = new Date(date.getTime())
        let offset = date.getTimezoneOffset() / 60
        newDate.setHours(phours - offset)
        return newDate.getTime()
      }
    },
    combineStartEnd(startDate, endDate) {
      let timeString = endDate.substring(endDate.indexOf('T') + 1)
      let dateString = startDate.substring(0, startDate.indexOf('T'))
      if (timeString == '00:00:00') {
        return dateString + 'T' + '00:30:00'
      } else {
        return dateString + 'T' + timeString
      }
    },
    async getStateAndCampMeetings(start_date, end_date) {
      this.events = []
      this.tempEvents = []
      this.getMeetings(this.userCampKey, start_date, end_date)
    },
    async getMeetings(org_key, start_date, end_date) {
      await calendarService
        .getMeetings(org_key, start_date, end_date)
        .then(res => {
          this.addEvents(org_key, res)
        })
        .catch(err => {
          console.error(err)
        })
    },
    async getSelected(itemSelected) {
      try {
        let destination = {},
          evt = {}
        if (!!itemSelected.event) {
          evt = itemSelected.event
          Object.assign(evt, evt.extendedProps)
        } else {
          evt = itemSelected
        }
        if (!!evt.mtrkey && evt.mtrkey !== constantData.empty_guid) {
          let response = await calendarService.getMeetingRecurrence(evt.mtrkey)
          if (response.status === 200) {
            evt.reccurrencetype = response.data
            await this.clearSelected()
            await this.setSelectedMeeting(evt)
            if (!!evt.mtrkey && evt.mtrkey !== constantData.empty_guid) {
              destination.path =
                evt.scope === 'State' || evt.scope === 'Country'
                  ? '/calendar/state/event/event-detail/'
                  : '/calendar/camp-calendar/event-detail'
            }
          }
        } else {
          switch (evt.meetingtype) {
            case constantData.meetings.gideonscardsshowing.value:
              await this.setSelectedVisitKey(evt.mtgkey)
              destination.path = constantData.meetings.gideonscardsshowing.route
              break
            case constantData.meetings.gideonsscripturesplacement.value:
              await this.setSelectedPlacementDateKey(evt.mtgkey)
              destination.path = constantData.meetings.gideonsscripturesplacement.route
              break
            case constantData.meetings.gideonrestockingvisit.value:
              await this.setSelectedVisitKey(evt.mtgkey)
              destination.path = constantData.meetings.gideonrestockingvisit.route
              break
            case constantData.meetings.churchpresentation.value:
              await this.setSelectedMeetingKey(evt.mtgkey)
              destination.path = constantData.meetings.churchpresentation.route
              break
            case constantData.meetings.stateconvention.value:
              this.setSelectedConventionKey(evt.mtgkey)
              destination.path = constantData.meetings.stateconvention.route
              break
            case constantData.meetings.TwoHourConversation.value:
            case constantData.meetings.TwoDayInstructor.value:
            case constantData.meetings.TwoDayEducationalUnit.value:
              await this.setSelectedMeetingKey(evt.mtgkey)
              destination.path = constantData.meetings.TwoHourConversation.route
              break
            default:
              if (!!evt.distribution) {
                await this.setSelectedMeeting(evt)
                destination.path = '/calendar/state/event/event-detail/'
              } else {
                await this.clearSelected()
                await this.setSelectedMeeting(evt)
                destination.path =
                  evt.scope === 'State' || evt.scope === 'Country'
                    ? '/calendar/state/event/event-detail/'
                    : '/calendar/camp-calendar/event-detail'
              }
              break
          }
        }
        if (!!destination.path) {
          this.$router.push(destination)
        }
      } catch (ex) {
        console.error('ex', ex)
      }
    },
    filterMeeting(obj) {
      if (obj.type === 'member') {
        if (obj.value === 'all') {
          this.events = this.tempEvents
        } else {
          this.events = this.tempEvents.filter(item => {
            return !!item.vmpt_trn_txt && item.vmpt_trn_txt.toLowerCase() === obj.value
          })
        }
        if (!!this.$refs.fullcalendar) {
          let calendar = this.$refs.fullcalendar.getApi()
          calendar.getEventSources()[0].remove()
          calendar.addEventSource(this.events)
        }
      } else if (obj.type === 'daterange') {
        this.getStateAndCampMeetings(obj.value.startDate, obj.value.endDate)
      }
    },
    getToBeScheduleMeeting(org_key) {
      calendarService.MeetingToBeSchedule(org_key).then(res => {
        if (res.status == 200) {
          this.toBeScheduled = res.data.meeting.map(item => {
            const translatedText =
              this.translations.common['calendar-event-type'][item.vmtt_mtt_key] || item.vmtt_trn_txt
            return { vmtt_mtt_key: item.vmtt_mtt_key, vmtt_trn_txt: translatedText }
          })
        }
      })
    },
    addToBeScheduleEvent(event) {
      this.$router.push({
        path: `/calendar/camp-calendar/add-event/${event.vmtt_mtt_key.toLowerCase()}`
      })
    },
    async selectedEvent(event) {
      await this.getSelected(event)
    },
    today(isToday) {
      if (isToday) {
        let date = moment().format('YYYY-MM-DD')
        this.getStateAndCampMeetings(date, date)
      } else {
        this.getStateAndCampMeetings(null, null)
      }
    },
    addEvents(org_key, res) {
      this.getToBeScheduleMeeting(org_key)
      this.events = [
        ...this.events,
        ...res.data.meetings.map(item => {
          item.color =
            item.vmpt_trn_txt === 'Gideon'
              ? 'card-Gideon'
              : item.vmpt_trn_txt === 'Auxiliary'
              ? 'card-Auxillary'
              : 'card-Both'
          return this.meetingDetails(item)
        })
      ]
      this.tempEvents = [...this.tempEvents, ...this.events]
      if (!!this.$refs.fullcalendar) {
        let calendar = this.$refs.fullcalendar.getApi()
        if (calendar.getEventSources().length > 0) {
          calendar.getEventSources()[0].remove()
        }
        calendar.addEventSource(this.events)
      }
    },
    formattedDate(date) {
      return this.returnFormattedDate(date)
    }
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userLogin: 'user/userLogin'
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
    ...mapState(['user']),
    days() {
      //  Used to display date on grid display
      const days = {}
      this.events.sort((a, b) => {
        return new Date(a.startdate) - new Date(b.startdate)
      })
      this.events.forEach(ev => {
        const categoryKey = ev.mtg_category_key
        const eventTypeKey = ev.mtg_mtt_key
        const distributionPlacementKey = ev.mtg_distribution_placement_key

        if (this.translations.newCalendarEventTypes.hasOwnProperty(distributionPlacementKey)) {
          ev.title = this.translations.newCalendarEventTypes[distributionPlacementKey]
        } else if (this.translations.newCalendarEventTypes.hasOwnProperty(eventTypeKey)) {
          ev.title = this.translations.newCalendarEventTypes[eventTypeKey]
        } else if (this.translations.newCalendarEventTypes.hasOwnProperty(categoryKey)) {
          ev.title = this.translations.newCalendarEventTypes[categoryKey]
        } else {
          ev.title = ev.meetingtitle
        }

        if (!days[ev.startdate]) {
          days[ev.startdate] = []
        }
        days[ev.startdate].push(ev)
      })
      return days
    }
  },
  components: {
    Calendar: Calendar,
    CalendarKey: CalendarKey,
    CalendarToolbar: CalendarToolbar,
    CalendarWeek: CalendarWeek,
    FullCalendar,
    campSelector: CampSelect,
    iAdd: iAdd,
    iClose: iClose,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageHeader: pageHeader,
    securityBanner: SecurityBanner
  }
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
.fullcalvis {
  visibility: visible;
  height: auto;
}
.fullcalinv {
  visibility: hidden;
  height: 0px;
}
.fc-event {
  font-size: 0.85em !important;
  background-color: #3a87ad;
  border-color: #3a87ad;
  line-height: 1.3;
  border-radius: 3px;
  border: 1px solid #3a87ad;
  cursor: pointer;
}
.fc-event-time {
  min-width: fit-content;
}
.fc .fc-col-header-cell-cushion {
  color: #636363;
  text-decoration: none;
}
.fc-timegrid-event {
  font-size: 0.9em !important;
  min-height: 27px;
  line-height: 0.95em;
}
.fc-daygrid-day-number {
  display: flex;
  text-decoration: none !important;
  justify-content: center;
  align-items: center;
  float: right;
  width: 30px;
  height: 30px;
  font-size: 14px;
  color: #636363;
  font-family: $open-sans;
  @include breakpoint(sm) {
    margin: 0 auto;
    float: none;
  }
}

.to-be-scheduled {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fbc301;
  header {
    display: flex;
    h3 {
      color: #000;
      font-family: 'DIN 1451 W01 Engschrift', sans-serif;
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 44px;
      text-transform: uppercase;
      flex: 1 1 auto;
      display: flex;
      @include breakpoint(sm) {
        justify-content: center;
      }
    }
    .i-tooltip {
      position: relative;
      top: 0px;
      margin-left: 10px;
      line-height: 0;
      svg path {
        fill: #000;
      }
    }
  }
  .scheduled-buttons {
    .btn {
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      @include breakpoint(sm) {
        width: 100%;
        text-align: left;
        span {
          flex: 1 1 auto;
        }
      }
      svg {
        margin-left: 7px;
      }
    }
  }
}
.calendar-month {
  margin-bottom: 80px;
  .section-1 {
    padding-top: 30px;
    @include breakpoint(sm) {
      margin-top: 50px;
    }
  }
  .cal-key-cont {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
    margin-bottom: 30px;
    .calendar-key:last-of-type .event-key {
      margin-right: 0;
    }
    @include breakpoint(sm) {
      display: none;
    }
  }
  .month-view {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    @include breakpoint(sm) {
      padding-left: 0;
      padding-right: 0;
    }
    h3 {
      color: #000;
      font-size: 42px;
      line-height: 44px;
      margin-bottom: 30px;
      text-transform: none;
      @include breakpoint(sm) {
        padding-left: 55px;
        padding-right: 55px;
      }
    }
    .day-view {
      padding-bottom: 85px;
      @include breakpoint(sm) {
        padding-bottom: 30px;
      }
    }
  }
  .card {
    padding: 0;
    box-shadow: none !important;
    .card-header {
      display: none;
    }
    .card-block {
      .row > .col-sm-12 > .row {
        display: none;
      }
      .full-calendar-body {
        .week-row {
          border-color: #efefef;
          .day-cell {
            padding: 5px 5px;
            min-height: 150px;
            @include breakpoint(sm) {
              min-height: 100px;
              border: none;
              > .row > .col-sm-6 {
                &:first-of-type {
                  display: none;
                }
                &:last-of-type {
                  width: 100%;
                }
              }
            }
          }
          .event-box {
            .card-header {
              display: block !important;
              font-size: 14px;
              padding: 8px 15px;
              border-bottom: none;
              @include breakpoint(sm) {
                display: none;
              }
            }
            .card {
              box-shadow: none !important;
              @include breakpoint(sm) {
                width: 13px;
                height: 13px;
                border: none !important;
                border-radius: 50%;
                background-color: #636363 !important;
                margin: 0 auto;
              }
              &.card-Gideon {
                background-color: #f1f4f5;
                border-left: 5px solid #1ca3c2;
                .card-header {
                  color: #1ca3c2;
                }
              }
              &.card-Auxillary {
                background-color: #fef4f1;
                border-left: 5px solid #fb4401;
                .card-header {
                  color: #fb4401;
                }
              }
              &.card-Both {
                background-color: #f3f1f5;
                border-left: 5px solid #2a0049;
                .card-header {
                  color: #2a0049;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
